import React from 'react';
import { IntlProvider } from 'react-intl';
import { graphql } from 'gatsby';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Layout from '../components/layout';
import ContentWrapper from '../components/ContentWrapper';

import getMessages from '../locales/getMessages';


const messages = getMessages();

const translate = {
  en: {
    title: 'Troubleshooting - EverTool',
    subtitle: 'Troubleshooting of EverTool',
  },
  'zh-tw': {
    title: '疑難排解 - EverTool',
    subtitle: 'EverTool 的疑難排解',
  },
  'zh-cn': {
    title: '疑难排解 - EverTool',
    subtitle: 'EverTool 的疑难排解',
  },
  ja: {
    title: 'トラブルシューティング - EverTool',
    subtitle: 'EverToolのトラブルシューティング',
  },
};

const TroubleshootingTemplate = ({ data, pageContext }) => {
  const { markdownRemark, site } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;
  const { locale, path } = frontmatter;
  const { url } = site.siteMetadata;

  return (
    <Layout
      pageContext={pageContext}
      seo={{
        title: `${translate[locale].title} - EverTool`,
        subtitle: translate[locale].subtitle,
        hreflangs: pageContext.hreflangs,
        url: `${url}${path}`,
        locale,
      }}
    >
      <IntlProvider locale={locale} messages={messages[locale]} textComponent={React.Fragment}>
        <div>
          <Header />
          <ContentWrapper isMarkdown>
            <h1>{translate[locale].title}</h1>
            <div className="blog-post">
              <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </ContentWrapper>
        </div>
        <Footer />
      </IntlProvider>
    </Layout>
  );
};

export default TroubleshootingTemplate;

export const pageQuery = graphql`
  query TroublePath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        locale
      }
    }
    site {
      siteMetadata {
        url
      }
    }
  }
`;
